<template>
  <b-modal id="article-filters-modal" size="lg" title="Re-Assign Article Prices" v-model="showModal"
           v-if="selectedArticle">
    <b-overlay :show="loading" rounded="sm">
      <p>This will re-assign all price records assigned to: <strong>{{ selectedArticle.title }}</strong>. Please select a replacement article to
        transfer prices to and click Confirm </p>

      <div class="actions">
        <div v-if="newSelectedArticle">
          <span>All prices for this article will be transferred to: </span>
          <span><strong>{{ newSelectedArticle.latina_article_id }} - {{ newSelectedArticle.title }}</strong></span>
          <b-button variant="warning" @click="toggleArticleSearch" size="sm">Back</b-button>
        </div>
        <div v-else>
          <div class="articleSearch">
            <b-form-input id="articleSearchSearch" ref="searchInput" size="sm" v-on:keyup.enter="performSearch"
                          v-model="searchTerm"></b-form-input>
            <b-button variant="primary" @click="performSearch" size="sm">Search</b-button>
            <b-button variant="warning" @click="toggleArticleSearch" size="sm">Back</b-button>
          </div>
          <b-overlay :show="isFetching" class="articleResults__container">
            <div class="articleResults__results" v-if="searchResults.length > 0">
              <div class="articleResults__result" v-for="result in searchResults" :key="result.id"
                   v-on:click="selectArticle(result)">
                {{ result.latina_article_id }} - {{ result.title }}
              </div>
            </div>
          </b-overlay>
        </div>
      </div>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="success" @click="saveReassignment">
        Confirm
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "DeactivateArticleModal",
  data() {
    return {
      supplier: null,
      showArticleSearch: true,
      searchTerm: '',
      newSelectedArticle: false,
    }
  },
  components: {},
  computed: {
    showModal: {
      get() {
        return this.$store.state.articles.modals.isDeactivateModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('articles/openDeactivateModal');
        } else {
          this.$store.dispatch('articles/closeDeactivateModal');
        }
      }
    },
    ...mapState({
      loading: state => state.articles.isLoading,
      suppliers: state => state.ancillaries.suppliers,
      selectedArticle: state => state.articles.currentArticle,
      searchResults: (state) => state.newPriceList.articleResults,
      isFetching: (state) => state.newPriceList.isFetching,
    })
  },

  mounted() {
    this.showArticleSearch = true;
    this.searchTerm = '';
    this.newSelectedArticle = false;
  },
  methods: {
    reset() {
      this.$store.dispatch('articles/closeDeactivateModal');
    },
    toggleArticleSearch() {
      this.showArticleSearch = !this.showArticleSearch;
      this.newSelectedArticle = false;
      this.$store.dispatch('newPriceList/clearArticleSearch');
      this.$store.dispatch('prices/clearCompletedStatus');
    },
    performSearch() {
      this.$store.dispatch('newPriceList/searchArticles', this.searchTerm)
    },
    selectArticle(article) {
      this.newSelectedArticle = article;
    },
    async saveReassignment() {
      await this.$store.dispatch('articles/deActivateArticle', {
        oldArticleId: this.selectedArticle.id,
        newArticleId: this.newSelectedArticle.id
      });

    },
  }

}
</script>

<style lang="scss">

.actions {
  padding: 10px;
  background: #d6f0ff;
  display: flex;
  justify-content: center;
  border-radius: 5px;

  button {
    margin: 0 3px;
    font-size: 12px;
  }

}

.articleSearch {
  display: flex;
  align-items: center;

  input {
    flex-grow: 1;
  }
}

#article-filters-modal {
  .articleResults {

    &__results {
      height: 210px;
      position: absolute;
      background: #fff;
      padding: 5px 0;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      overflow-y: auto;
      width: 330px;
      left: 0;
      top: 2px;
      box-shadow: 0 1px 4px #b9b9b9;
    }

    &__result {
      padding: 2px 10px;
      width: 100%;
      text-align: left;
      cursor: pointer;
      display: block;
      border-bottom: solid 1px #eee;
      transition: all 0.3s;

      &:hover {
        color: #1e90c3;
      }
    }
  }
}
</style>